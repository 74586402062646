import React, { FC, useEffect, useState } from 'react';
import { Prestation } from '../../model/Prestation';
import { getDownloadURL, ref as documentRef } from "firebase/storage";
import firebaseModules from "../../services/firebase.service";
import { LayoutGroup, motion } from 'framer-motion';
import { ModalContext } from '../OnePage';
import { PrestationTileContentStyled, PrestationTileImageStyled, PrestationTileNameStyled, PrestationTileOverlayStyled, PrestationTileStyled } from './styled';
type PrestationTileProp = Prestation & {
  isInBackground: boolean;
}
const PrestationTile: FC<PrestationTileProp> = ({ name, main_image, description, isInBackground, page }) => {
  const [image, setImage] = useState<string>();

  useEffect(() => {
    getDownloadURL(
      documentRef(firebaseModules.storage, main_image)
    ).then((url) => {
      setImage(url)
    });
  });
  const getContent = () => {
    if (isInBackground) {
      return;
    }
    return (
      <ModalContext.Consumer>
        {modal =>
        (<div onClick={() => {
          if (modal.changeState) {
            modal.changeState({ modalPageId: page.id, imageUrl: image });
            document.body.style.overflow = 'hidden'
          }
        }}>
          <PrestationTileOverlayStyled />
          <PrestationTileNameStyled>{name}</PrestationTileNameStyled>
          <PrestationTileContentStyled>{description}</PrestationTileContentStyled>
        </div>
        )}
      </ModalContext.Consumer>
    );
  }
  return (
    <PrestationTileStyled>
      {getContent()}
      <PrestationTileImageStyled>
        <LayoutGroup>
          <motion.img className="image" layoutId={page?.id} src={image} alt={name} />
        </LayoutGroup>
      </PrestationTileImageStyled>
    </PrestationTileStyled>
  )
}

export default PrestationTile;