import CloseIcon from '@mui/icons-material/Close';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import breakpoints from '../../breakpoint';

export const BackdropStyled = styled(motion.div)`
    position: fixed;
    overflow: auto;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
`;

export const PageStyled = styled.div`
    @media screen and (max-width: ${breakpoints.lg}) {
        width: 100vw;
        margin-left: 0vw;
        margin-top: 0vh;
     }
    position: relative;
    overflow: visible;
    border-radius: 35px;
    background-color: #3E2251;
    margin-left: 10vw;
    margin-top: 5vh;
    width: 80vw;
    min-height: 80vh;
`;

export const CloseButton = styled.div`
    position: absolute;
    display: flex;
    height: 21px;
    width: 21px;
    border-radius: 50px;
    padding: 8px;
    background-color: #3E2251;
    top: 15px;
    right: 15px;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    &:hover{
        cursor: pointer;
    }
`;

export const CloseIconStyled = styled(CloseIcon)`
    color: white;
`;

export const ImageStyled = styled.div`
    height: 30vh;
    position: relative;
    border-top-left-radius: 35px;
    border-top-right-radius: 35px;
    overflow: hidden;
    img {
        position: absolute;
        left: -1000%;
        right: -1000%;
        top: -1000%;
        bottom: -1000%;
        margin: auto;
        max-width: 100%;
    }
`;
export const PageTitle = styled.div`
    margin-right: 25px ;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 42px;
    line-height: 62px;
    color: #FFFFFF;
`;
export const PageContainerTitle = styled.div`
    position: absolute;
    background: linear-gradient(180deg, rgba(62, 34, 81, 0) 0%, #3E2251 100%);
    width: 100%;
    height: 10vh;
    top: 20vh;
    display: flex;
    align-items: end;
    justify-content: flex-end;
`;
export const PageContentStyled = styled.div`
    font-family: 'Poppins';
    padding: 25px 50px;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.145em;
    color: #F2E8DA;
`;