import { Link } from 'react-router-dom';
import styled from 'styled-components';
export const LegalContainer = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
`;
export const LegalContains = styled.div`
    width: 40vw;
    position: relative;
    background-color: white;
    padding: 5%;
    border-radius: 15px;
`;
export const CloseButton = styled(Link)`
    position: absolute;
    display: flex;
    height: 21px;
    width: 21px;
    border-radius: 50px;
    padding: 8px;
    top: 15px;
    right: 15px;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    &:hover{
        cursor: pointer;
    }
`;
