import { doc, getDoc } from 'firebase/firestore';
import { Page } from '../model/Page';
import firebaseModules from "./firebase.service";

export default class Pages {
    async get(id: string) {
        const docRef = doc(firebaseModules.db, "page", id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            return docSnap.data() as Page;
        }
    }
}