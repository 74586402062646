import { Link } from 'react-scroll';
import styled from 'styled-components';
import breakpoints from '../../breakpoint';
export const StyledNav = styled.div`
    position: sticky;
    top: 0;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-content: center;
    justify-content: space-evenly;
    align-items: center;
    height: 50px;
    z-index: 999999999999;
    background-color: #3E2251;
`;
export const StyledLink = styled(Link)`
    @media screen and (max-width: ${breakpoints.lg}) {
      font-size: 12px;
    }
    color: #F2E8DA;
    height: 100%;
    width: 20%;
    margin: 0% auto;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    transition: all 300ms ease-in-out;
    &:hover{
        color: #3E2251;
        background-color: #F2E8DA;
    }
`
export const Lotus = styled.img`
    @media screen and (max-width: ${breakpoints.lg}) {
     display: none;
    }
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 20%; 
    width: 50px;
    cursor: pointer;
`