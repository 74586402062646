import { DocumentData } from '@firebase/firestore';
import { motion } from 'framer-motion';
import { FC, useEffect, useState } from 'react';
import PrestationTile from './PrestationTile';
import { Prestation } from '../../model/Prestation';
import Prestations from '../../services/prestation.service';
import { PrestationBlurListStyled, PrestationListStyled, PrestationTileListStyled, PrestationTitleStyled } from './styled';

const PrestationList: FC = () => {
    const [prestations, setPrestations] = useState<DocumentData[]>([]);

    useEffect(() => {
        new Prestations().getAll().then((docs) => {
            setPrestations(docs);
        })
    }, []);

    return (
        <motion.div id='prestation'>
            <PrestationListStyled>
                <PrestationTitleStyled>Je vous propose</PrestationTitleStyled>
                <PrestationBlurListStyled>
                    {prestations.sort((a, b) => a.order - b.order)
                        .filter((val) => val.published)
                        .map((doc) => doc as Prestation)
                        .map((doc, index) => (
                            <motion.div
                                viewport={{ once: true }}
                                initial={{ opacity: 0, y: 100 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                transition={{ type: "easeIn", duration: .4, delay: .3 * index }}
                            >
                                <PrestationTile key={index} {...doc} isInBackground={true} />
                            </motion.div>

                        ))}
                </PrestationBlurListStyled>
                <PrestationTileListStyled>
                    {prestations.sort((a, b) => a.order - b.order)
                        .filter((val) => val.published)
                        .map((doc) => doc as Prestation)
                        .map((doc, index) => {
                            return (
                                <motion.div
                                    viewport={{ once: true }}
                                    initial={{ opacity: 0, y: 100 }}
                                    whileInView={{ opacity: 1, y: 0 }}
                                    transition={{ type: "easeIn", duration: .7, delay: .3 * index }}
                                >
                                    <PrestationTile key={index} {...doc} isInBackground={false} />
                                </motion.div>

                            );
                        })}
                </PrestationTileListStyled>
            </PrestationListStyled>
        </motion.div>

    )
}
export default PrestationList;