import styled from 'styled-components';

export const StyledLogo = styled.div`
   
    width: 100vw;
    background: linear-gradient(180deg, #57386B 0%, #160E1B 100%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;
export const ButtonLogo = styled.div`
    margin: 8px;
    img {
        width: 50px;
        cursor: pointer;
    }
`;
export const ImageLogo = styled.div`

    height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const LogoTextContainer = styled.div`
    position: absolute;
    top: -20%;
    left: 0;
    right: 0;
    bottom: 0;
    color: #F2E8DA;
    font-size: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
`;
export const LogoText = styled.div`
`;