import { FC } from 'react';
import ReactMarkdown from 'react-markdown';
import { ContentStyled, TitleContent } from './styled';
type ContentProps = {
    title: string;
    content: string;
}
const Content: FC<ContentProps> = ({ title, content }) => (
    <ContentStyled
        viewport={{ once: true }}
        initial={{ opacity: 0, y: 100 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ type: "easeIn", duration: .7, delay: .3 }}
        className="section__content">
        <TitleContent>{title}</TitleContent>
        <ReactMarkdown>{content}</ReactMarkdown>
    </ContentStyled>
);

export default Content;