import { motion } from 'framer-motion';
import styled from 'styled-components';
import breakpoints from '../../breakpoint';
export const AvisSection = styled.div`
   
    overflow: auto;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y:hidden;
    &::-webkit-scrollbar {
    display: none;
  }
`;
export const AvisTitle = styled.div`
    @media screen and (max-width: ${breakpoints.lg}) {
      font-size: 24px;
    }
    color: #F2E8DA;
    display: block;
    width: fit-content;
    padding: 2rem;
    font-size: 48px;
    margin-left: 2vw;
    border-radius: 15px;
    background: #29173685;
`;
export const AvisContainer = styled.div`
 @media screen and (max-width: ${breakpoints.lg}) {
      margin: 20% 0;
    }
    margin: 10% 0;
`

export const CardItemStyled = styled(motion.div)`
    @media screen and (max-width: ${breakpoints.lg}) {
         width: 95%;
     }
    position: relative;
    padding: 2%;
    flex: 0 0 auto;
    margin: 2%;
    min-height: 250px;
    border-radius: 15px;
    width: 95%;
    background: #29173685;
    color: #F2E8DA;
    white-space: pre-line;
`;
export const CardTitle = styled.p`
    font-weight: bold;
`;

export const DateCard = styled.div`
    position: absolute;
    right: 15px;
    bottom: 15px;
    font-style: italic;
    color: grey;
`