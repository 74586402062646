import { Link } from 'react-router-dom';
import styled from 'styled-components';
export const StyledFooter = styled.div`
    height: 100px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
`;
export const StyledLink = styled(Link)`
    color: white;
`