import { collection, getDocs, getDocsFromCache } from "firebase/firestore";
import { Section } from '../model/Section';
import firebaseModules from "./firebase.service";

export default class Sections {
    async getAll() {
        let querySnapshot = await getDocsFromCache(collection(firebaseModules.db, "sections"));
        if (querySnapshot) {
            querySnapshot = await getDocs(collection(firebaseModules.db, "sections"));
        }
        return querySnapshot.docs.map(doc => doc.data()).map((document) => document as Section);
    }
}