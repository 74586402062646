import { AnimatePresence } from 'framer-motion';
import React, { FC, useEffect, useState } from "react";
import { Section } from "../model/Section";
import PageModal from './Pages/PageModal';
import PrestationList from './Prestation/PrestationList';
import Sections from "../services/sections.service";
import AvisLayout from './Avis/AvisLayout';
import { ContactPage } from './Contact/ContactPage';
import Header from "./Header/Header";
import StickyNav from './Nav/StickyNav';
import "./onePage.scss";
import SectionLayout from './Section/SectionLayout';
import Citation from './Citation/Citation';
import Footer from './Footer/Footer';


type ModalContextProps = {
  state?: ModalState;
  changeState?: React.Dispatch<React.SetStateAction<ModalState | undefined>>
}
type ModalState = {
  modalPageId?: string;
  imageUrl?: string;
  changeState?: React.Dispatch<React.SetStateAction<ModalState | undefined>>
}

export const ModalContext = React.createContext<ModalContextProps>({});
export const IsMobileContext = React.createContext<boolean>(false);
export const IsTabletContext = React.createContext<boolean>(false);
const OnePage: FC = () => {
  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const isMobile = width <= 768;
  const isTablet = width <= 834;
  console.log(width, isTablet);
  const [modalState, setModalState] = useState<ModalState>();
  const [sections, setSection] = useState<Section[]>([]);

  useEffect(() => {
    new Sections().getAll().then((docs) => {
      setSection(docs);
    })
  }, [])

  return (
    <IsTabletContext.Provider value={isTablet}>
      <IsMobileContext.Provider value={isMobile}>
        <ModalContext.Provider value={{ changeState: setModalState, state: modalState }}>
          <main>
            <AnimatePresence exitBeforeEnter>
              <Header />
              <ModalContext.Consumer>
                {modal => modal.state != null ? <></> : <StickyNav sections={sections} />}
              </ModalContext.Consumer>

            </AnimatePresence >
            {sections.length > 0 ? (
              sections!
                .sort((a, b) => a.order - b.order)
                .filter((val) => val.published)
                .map((doc) => doc as Section)
                .map((doc, index) => (
                  <SectionLayout key={index} {...doc}></SectionLayout>
                ))
            ) : (
              <div></div>
            )}
            <PrestationList />
            <PageModal />
            <Citation />
            <AvisLayout />
            <ContactPage />
            <Footer />
          </main>
        </ModalContext.Provider>
      </IsMobileContext.Provider>
    </IsTabletContext.Provider>
  );
}


export default OnePage;
