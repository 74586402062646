import { motion } from 'framer-motion';
import styled from 'styled-components';
import image from '../../assets/image/chateau.jpg'
import breakpoints from '../../breakpoint';

export const ContactContainer = styled.div`
    height: 100vh;
    width: 100vw;
    background: url(${image});
    background-size: cover;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-content: center;
`;
export const ContactContentSubtitle = styled.p`
    font-size: 32px;
`;
export const ContactMailTo = styled.a`
    font-size: 32px;
    color: #FFFFFF;
`;
export const ContactImage = styled(motion.img)`
     width: 592px;
     border-radius: 35px;
     margin: 2%;
     align-self: flex-end;
     @media screen and (max-width: ${breakpoints.lg}) {
        display: none;
     }
`;

export const ContactContent = styled(motion.div)`
    @media screen and (max-width: ${breakpoints.lg}) {
         width: 100%;
         align-self: center;
     }
    width: 45%;
    padding: 3%;
    height: auto;
    align-self: flex-start;
    background: linear-gradient(0deg, rgba(62, 34, 81, 0.37) 41.95%, rgba(62, 34, 81, 0) 100%);
    margin: 2%;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 72px;
    text-align: center;
    color: #FFFFFF;
    border-radius: 35px;
`;