import { motion } from 'framer-motion';
import { FC } from 'react';
import { CitationContainer } from './styled';

const Citation: FC = () => {
    return (
        <CitationContainer

        >
            <motion.p
                viewport={{ once: true }}
                initial={{ opacity: 0, y: 100 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ type: "easeIn", duration: .7, delay: .3 }}>
                “Vous pensez être la peine, vous êtes le médicament qui la guérit
            </motion.p>
            <motion.p
                viewport={{ once: true }}
                initial={{ opacity: 0, y: 100 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ type: "easeIn", duration: .7, delay: .6 }}>
                Vous pensez être la serrure de votre coeur, en réalité vous êtes la clé qui l’ouvre.”
            </motion.p>
            <motion.p
                viewport={{ once: true }}
                initial={{ opacity: 0, y: 100 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ type: "easeIn", duration: .7, delay: .9 }}>
                Djalal Al-Din Rumi
            </motion.p>
        </CitationContainer>
    );
}

export default Citation;