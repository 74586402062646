import { useScroll, useSpring, useTransform } from 'framer-motion';
import { FC, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { getDownloadURL, ref as documentRef } from "firebase/storage";
import firebaseModules from "../../services/firebase.service";
import { ImageStyled } from './styled';
type ImageProps = {
    main_image: string;
    title: string;
}
const Image: FC<ImageProps> = ({ main_image, title }) => {
    const [image, setImage] = useState<string>();
    useEffect(() => {
        getDownloadURL(
            documentRef(firebaseModules.storage, main_image)
        ).then((url) => {
            setImage(url)
        });
    });
    const offset = 25;
    const [elementTop, setElementTop] = useState(0);
    const [clientHeight, setClientHeight] = useState(0);
    const ref = useRef<HTMLDivElement>(null);

    const { scrollY } = useScroll()

    const initial = elementTop - clientHeight;
    const final = elementTop + offset;

    const yRange = useTransform(scrollY, [initial, final], [offset, -offset])
    const y = useSpring(yRange, { stiffness: 400, damping: 90 })

    useLayoutEffect(() => {
        if (!ref.current) return
        const element = ref.current;
        const onResize = () => {
            setElementTop(element.getBoundingClientRect().top + window.scrollY || window.pageYOffset);
            setClientHeight(window.innerHeight)
        }
        onResize()
        window.addEventListener('resize', onResize)
        return () => window.removeEventListener('resize', onResize)
    }, [ref])

    return (
        <ImageStyled
            viewport={{ once: true }}
            initial={{ opacity: 0, y: 100 }}
            whileInView={{ opacity: 1, }}
            transition={{ type: "easeIn", duration: .7, delay: .3 }}
            ref={ref}
            style={{ y }}>
            {image ?
                <div><img src={image} alt={title} /></div>
                : null}
        </ImageStyled>);
};

export default Image;