// Import the functions you need from the SDKs you need
import { FirebaseApp, initializeApp } from "firebase/app";
import { getFirestore, Firestore } from "firebase/firestore";
import { getStorage, FirebaseStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDxTVkkHEli4lcuhfbmsKXbCoruY2j_27M",
  authDomain: "energ-sens.firebaseapp.com",
  projectId: "energ-sens",
  storageBucket: "energ-sens.appspot.com",
  messagingSenderId: "961320977107",
  appId: "1:961320977107:web:8947dcc3fdb5eb23954a26",
  measurementId: "G-8XXLBVLB2L"
};
class FirebaseModules {
  app: FirebaseApp;
  storage: FirebaseStorage;
  db: Firestore;
  constructor(storage: FirebaseStorage, db: Firestore, app: FirebaseApp) {
    this.storage = storage;
    this.db = db;
    this.app = app;
  }
}
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);
export default new FirebaseModules(storage, db, app);

