import { intlFormat } from 'date-fns';
import { Timestamp } from 'firebase/firestore';
import React, { FC } from 'react'
import { CardItemStyled, CardTitle, DateCard } from './styled';
type AvisItemProps = {
    name: string;
    comment: string;
    date: Timestamp;
}
const AvisItem: FC<AvisItemProps> = ({ name, comment, date }) => {
    return (
        <CardItemStyled
            viewport={{ once: true }}
            initial={{ opacity: 0, y: 100 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ type: "easeIn", duration: .7 }}>
            <CardTitle>{name}</CardTitle>
            <div>{comment}</div>
            <DateCard>{intlFormat(date.toDate(), {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
            }, { locale: 'fr-FR', })}</DateCard>
        </CardItemStyled>
    );
}
export default AvisItem;