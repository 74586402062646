import styled from 'styled-components';
export const CitationContainer = styled.div`
    background-color: #29173685;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    min-height: 50vh;
    margin-bottom: 5%;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    text-align: center;
    color: #FFFFFF;
`;