
import { AnimatePresence } from 'framer-motion';
import { FC } from 'react';
import { ModalContext } from '../OnePage';
import PageLayout from './PageLayout';
import { BackdropStyled } from './styled';
const backdrop = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
}


const PageModal: FC = () => {
    return (<ModalContext.Consumer>
        {modal => {
            return (
                <AnimatePresence exitBeforeEnter>
                    {modal.state !== undefined && (
                        <BackdropStyled
                            variants={backdrop}
                            initial="hidden"
                            animate="visible"
                            exit="hidden"
                            onClick={() => {
                                if (modal.changeState) {
                                    modal.changeState(undefined);
                                    document.body.style.overflowY = 'visible'
                                }
                            }}
                        >
                            {modal.state.modalPageId && modal.state.imageUrl ?
                                <PageLayout id={modal.state.modalPageId} image={modal.state.imageUrl} /> : null}
                        </BackdropStyled>
                    )}
                </AnimatePresence>)
        }}
    </ModalContext.Consumer>
    )
}
export default PageModal;