import { FC, useEffect, useState } from 'react';
import { Avis } from '../../model/Avis';
import AvisService from '../../services/avis.service';
import AvisItem from './AvisItem';
import { AvisContainer, AvisTitle } from './styled';
import Carousel from '@itseasy21/react-elastic-carousel';
import { IsMobileContext } from '../OnePage';

const AvisLayout: FC = () => {
    const [avis, setAvis] = useState<Avis[]>([]);

    useEffect(() => {
        new AvisService().getAll().then((docs) => {
            setAvis(docs);
        })
    }, []);
    return (
        <AvisContainer id='avis'>
            <AvisTitle>Témoignages</AvisTitle>
            <IsMobileContext.Consumer>{isMobile => isMobile ?
                <Carousel isRTL={false} initialActiveIndex={0} itemsToShow={1} itemsToScroll={1} showArrows={false} >
                    {avis.reverse().map(((item, index) => (<AvisItem {...item} key={`avis-${index}`} />)))}
                </Carousel>
                :
                <Carousel isRTL={false} initialActiveIndex={0} itemsToShow={3} itemsToScroll={3} showArrows={false} enableSwipe={false}>
                    {avis.reverse().map(((item, index) => (<AvisItem {...item} key={`avis-${index}`} />)))}
                </Carousel>
            }</IsMobileContext.Consumer>

        </AvisContainer>
    )
}
export default AvisLayout;