import { motion } from 'framer-motion';
import styled from 'styled-components';
import breakpoints from '../../breakpoint';
import { Side } from '../../model/Section';

export const SectionStyled = styled.div(({ side }: Side) => `
    min-height: 80vh;
    margin: 15vh 0;
    padding: 5% 3%;
    background: #f2e8da;
    box-shadow: rgb(50 50 93 / 25%) 0px 30px 60px -12px inset, rgb(0 0 0 / 30%) 0px 18px 36px -18px inset;
    display: flex;
    flex-direction: ${side === 'left' ? 'row' : 'row-reverse'};
    justify-content: space-evenly;
`);

export const ImageStyled = styled(motion.div)`
    @media screen and (max-width: ${breakpoints.lg}) {
        display: none;
        width: 0%;
    }
    div {
        margin-left: 5%;
        height: 100%;
        overflow: hidden;
        border-radius: 35px;
        box-shadow: rgba(0, 0, 0, 90%) 40px 40px 44px, rgba(0, 0, 0, 40%) 0px 15px 12px;
        img {
            object-fit: cover;
            height: 100%;
            width: 100%;
        }
    }
`;

export const ContentStyled = styled(motion.div)`
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    align-items: flex-start;
    margin-left: 5%;
    width: 66%;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.145em;
    color: #3e2251;
    @media screen and (max-width: ${breakpoints.lg}) {
        width: 85%;
        margin: 0;
    }
    @media screen and (max-width: ${breakpoints.md}) {
       font-size: 16px;
       width: 85%;
    }
`
export const TitleContent = styled.p`
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 72px;
    color: #3e2251;
`