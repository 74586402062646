import * as React from 'react';
import { IsMobileContext } from '../OnePage';
import Logo from './Logo/Logo';
import MobileLogo from './Logo/MobileLogo';



export default class Header extends React.Component {
    render() {
        return <div className="header-column" id='header'>
            <IsMobileContext.Consumer>
                {isMobile => (isMobile ? <MobileLogo /> : <Logo />)}
            </IsMobileContext.Consumer>
        </div>
    }
}