import React, { FC, useEffect, useState } from 'react';
import Pages from '../../services/pages.service';
import { Page } from '../../model/Page';
import { LayoutGroup, motion } from 'framer-motion';
import ReactMarkdown from 'react-markdown';
import { CloseButton, CloseIconStyled, ImageStyled, PageContainerTitle, PageContentStyled, PageStyled, PageTitle } from './styled';
import { ModalContext } from '../OnePage';

type PageLayoutProps = {
    id: string;
    image: string;
}
const PageLayout: FC<PageLayoutProps> = ({ id, image }) => {
    const [page, setPage] = useState<Page>();

    useEffect(() => {
        if (id) {
            new Pages().get(id).then((page) => {
                if (page) {
                    setPage(page);
                }
            });
        }
    });
    return (
        <ModalContext.Consumer>
            {modal => {
                return (
                    <PageStyled>
                        <LayoutGroup>
                            <ImageStyled>
                                <motion.img className="image" layoutId={id} src={image} alt={page?.title} />
                            </ImageStyled>
                        </LayoutGroup>
                        <CloseButton onClick={() => {
                            if (modal.changeState) {
                                modal.changeState(undefined);
                                document.body.style.overflowY = 'visible';
                            }
                        }}>
                            <CloseIconStyled />
                        </CloseButton>
                        <PageContainerTitle>
                            <PageTitle>{page?.title}</PageTitle>
                        </PageContainerTitle>
                        <PageContentStyled>
                            <ReactMarkdown>{page?.content ?? ''}</ReactMarkdown>
                        </PageContentStyled>
                    </PageStyled>
                );
            }}
        </ModalContext.Consumer>
    );
}
export default PageLayout;