import { useScroll, useTransform, useSpring } from 'framer-motion';
import { FC, useLayoutEffect, useRef, useState } from 'react'
import { ContactContainer, ContactContent, ContactContentSubtitle, ContactImage, ContactMailTo } from './styled';
import image from '../../assets/image/bureau.jpg';

export const ContactPage: FC = () => {
    const offset = 25;
    const [elementTop, setElementTop] = useState(0);
    const [clientHeight, setClientHeight] = useState(0);
    const ref = useRef<HTMLDivElement>(null);

    const { scrollY } = useScroll()

    const initial = elementTop - clientHeight;
    const final = elementTop + offset;

    const yRange = useTransform(scrollY, [initial, final], [offset, -offset])
    const y = useSpring(yRange, { stiffness: 400, damping: 90 })

    useLayoutEffect(() => {
        if (!ref.current) return
        const element = ref.current;
        const onResize = () => {
            setElementTop(element.getBoundingClientRect().top + window.scrollY || window.pageYOffset);
            setClientHeight(window.innerHeight)
        }
        onResize()
        window.addEventListener('resize', onResize)
        return () => window.removeEventListener('resize', onResize)
    }, [ref]);

    return (
        <ContactContainer id='contact'>
            <ContactContent ref={ref} style={{ y }}>
                Energ & Sens
                <ContactContentSubtitle>
                    <ContactMailTo href="tel:+330670588367">06 70 58 83 67</ContactMailTo>
                </ContactContentSubtitle>
                <ContactContentSubtitle>6 rue du portail de ville</ContactContentSubtitle>
                <ContactContentSubtitle>Château de Chabons</ContactContentSubtitle>
                <ContactContentSubtitle>38110 La Tour-du-Pin</ContactContentSubtitle>
                <ContactMailTo href="mailto:contact@energ-sens.fr">
                    contact@energ-sens.fr
                </ContactMailTo>
            </ContactContent>
            <ContactImage src={image} style={{ y }} />
        </ContactContainer>
    )
}