import { FC } from "react";
import { Section } from "../../model/Section";
import { SectionStyled } from './styled';
import Image from './Image';
import Content from './Content';

const SectionLayout: FC<Section> = ({ content, side, order, title, main_image }) => {

  return (
    <SectionStyled
      side={side}
      id={`section-${order}`}
    >
      <Image main_image={main_image} title={title} />
      <Content title={title} content={content} />
    </SectionStyled>
  );
}
export default SectionLayout;
