import { FC } from 'react';
import { Section } from '../../model/Section';
import { Lotus, StyledLink, StyledNav } from './styled';
import lotus from "../../assets/image/lotus.png";
import { LayoutGroup } from 'framer-motion';
import { Link } from 'react-scroll';
type StickyNavProps = {
    sections: Section[];
}
const StickyNav: FC<StickyNavProps> = ({ sections }) => {
    return (
        <StyledNav>
            <LayoutGroup>
                <Link
                    activeClass="active"
                    to="header"
                    spy={true}
                    smooth={true}
                    offset={-10}
                    duration={400}
                >
                    <Lotus src={lotus} alt="" />
                </Link>
            </LayoutGroup>
            {
                sections.map((section) => (
                    <StyledLink
                        to={`section-${section.order}`}
                        spy={true}
                        smooth={true}
                        offset={-10}
                        duration={400}>{section.title}
                    </StyledLink>
                ))
            }
            <StyledLink
                to={`prestation`}
                spy={true}
                smooth={true}
                offset={-10}
                duration={400}>Je vous propose
            </StyledLink>
            <StyledLink
                to={`avis`}
                spy={true}
                smooth={true}
                offset={-40}
                duration={400}>Témoignages
            </StyledLink>
            <StyledLink
                to={`contact`}
                spy={true}
                smooth={true}
                offset={-10}
                duration={400}>Contact
            </StyledLink>
        </StyledNav>
    )
}
export default StickyNav;