import { FC } from 'react'
import { CloseButton, LegalContainer, LegalContains } from './styled';
import CloseIcon from '@mui/icons-material/Close';

const Legal: FC = () => {
    return (
        <LegalContainer>

            <LegalContains>
                <CloseButton to="/">
                    <CloseIcon />
                </CloseButton>
                <h1>Identification de l'éditeur</h1>
                <p>
                    Energ & Sens
                    6 Rue DU PORTAIL DE VILLE
                    38110 LA TOUR-DU-PIN
                </p>

                <p>Telephone : 06 70 58 83 67</p>

                <p>N° SIRET : 913 212 460 00012</p>

                <p> Code APE : 9609Z (autres services personnels n.c.a.)</p>

                <h1>Hebergement:</h1>

                <p>
                    L'hébergeur du site https://www.energ-sens.fr/ est la Société FIREBASE HOSTING
                    par GOOGLE, dont le siège social est situé au Google LLC, 1600 Amphitheatre Parkway,
                    Mountain View, California 94043 USA
                </p>

                <p>
                    Toute utilisation, reproduction, diffusion, commercialisation, modification de toute ou partie
                    du site https://www.energ-sens.fr/, sans autorisation de l’Editeur est prohibée et
                    pourra entraînée des actions et poursuites judiciaires telles que notamment prévues par le
                    Code de la propriété intellectuelle et le Code civil.
                </p>
            </LegalContains>
        </LegalContainer>)
}
export default Legal;