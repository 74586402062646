import { LayoutGroup, motion } from 'framer-motion';
import React from "react";
import { Link } from "react-scroll";
import logo from "../../../assets/image/logo.png";
import lotus from "../../../assets/image/lotus.png";
import { IsTabletContext } from '../../OnePage';
import "./logo.scss";

export default class Logo extends React.Component {
  render() {
    return (
      <div className="container">
        <div className="container__logo">
          <div className="container__logo__text">
            <motion.div animate={{ x: [100, 0], opacity: [0, 1] }} className="container__logo__text__1" >Energ</motion.div>
            <motion.div animate={{ opacity: [0, 1] }} className="container__logo__text__2">&</motion.div>
            <div className="container__logo__text__column">
              <motion.div animate={{ x: [-100, 0], opacity: [0, 1] }} className="container__logo__text__column__title">Sens</motion.div>
              <motion.div animate={{ y: [-50, 0], opacity: [0, 1], transition: { delay: .200 } }} className="container__logo__text__column__divider"></motion.div>
              <IsTabletContext.Consumer>
                {isTablet => (isTablet ?
                  <motion.div animate={{ y: [-50, 0], x: '-15%', opacity: [0, 1], transition: { delay: .500 } }} className="container__logo__text__column__sub-title">
                    énergétique
                  </motion.div> :
                  <motion.div animate={{ y: [-50, 0], opacity: [0, 1], transition: { delay: .500 } }} className="container__logo__text__column__sub-title">
                    énergétique
                  </motion.div>)
                }
              </IsTabletContext.Consumer>

            </div>
            <img
              className="container__logo__text__image"
              src={logo}
              alt="Logo Energ et Sens"
            />
          </div>
        </div>
        <motion.div animate={{ opacity: [0, 1] }} >
          <motion.div animate={{ y: [-20, 0, -20] }} transition={{ repeat: Infinity, duration: 5 }}>
            <Link
              activeClass="active"
              to="section-1"
              spy={true}
              smooth={true}
              offset={-10}
              duration={400}
            >
              <LayoutGroup>
                <div className="container__scroll-action">
                  <motion.img className="image" layoutId="lotus" src={lotus} alt="" />
                </div>
              </LayoutGroup>
            </Link>
          </motion.div>
        </motion.div>
      </div>
    );
  }
}
