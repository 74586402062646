import styled from 'styled-components';
import breakpoints from '../../breakpoint';

export const PrestationListStyled = styled.div`
    @media screen and (max-width: ${breakpoints.lg}) {
        padding: 0vh 0px;
        display: flex;
        position: relative;
        margin: 10vh 0;
        padding: 5vh 0px;
        background: #29173685;
        flex-direction: column;
        align-content: center;
        align-items: center;
    }
    position: relative;
    margin: 10vh 0;
    padding: 5vh 0px;
    background: #29173685;
`;
export const PrestationTitleStyled = styled.p`
    margin: 3%;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 72px;
    color: #FFFFFF;
`;
export const PrestationBlurListStyled = styled.div`
    @media screen and (max-width: ${breakpoints.lg}) {
      display: none;
    }
    position: absolute;
    padding: 5vh;
    transform: translate(2vh, 1vw);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    filter: blur(8px);
`;
export const PrestationTileListStyled = styled.div`
    @media screen and (max-width: ${breakpoints.lg}) {
       display: flex;
       flex-direction: column;
       padding: 1vw;
       width: 80vw;
    }
    margin-bottom: 5%;
    padding: 5vh;
    box-shadow: 0px 30px 60px -12px inset rgb(50 50 93 / 25%), rgb(0 0 0 / 30%) 0px 18px 36px -18px inset;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
`;

export const PrestationTileOverlayStyled = styled.div` 
    position: absolute;
    top: 12vw;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(179.96deg, rgba(22, 14, 27, 0) 0.03%, rgba(22, 14, 27, 0.675044) 14.97%, #160E1B 57.81%);
    border-radius: 5px;
    transition: all ease 400ms;
`;

const PrestationTileContent = styled.div`
    position: absolute;
    display: flex;
    margin-left: 5%;
    height: 90%;
    font-style: normal;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    flex-wrap: wrap;
    font-family: 'Poppins';
    font-weight: 400;
    color: #FFFFFF;
`;

export const PrestationTileNameStyled = styled(PrestationTileContent)`
    width: 90%;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    transition: all ease 500ms;
`;

export const PrestationTileContentStyled = styled(PrestationTileContent)`
    transform: translateY(40vh);
    margin-left: 15%;
    width: 70%; 
    font-size: 16px;
    line-height: 22px;
    align-items: center;
    text-align: center;
    transition: all ease 700ms;
`;

export const PrestationTileStyled = styled.div`
 @media screen and (max-width: ${breakpoints.lg}) {
      flex: 0 0  calc(80vw);
      height: calc(80vw);
      margin: 0;
      margin-bottom: 5vw;
    }
    &:hover{
        cursor: pointer;
        ${PrestationTileOverlayStyled} {
            top: 1vw;
        }
        ${PrestationTileNameStyled} {
            transform: translateY(-20vh);
        }
        ${PrestationTileContentStyled} {
            transform: translateY(10vh);
            @media screen and (max-width: ${breakpoints.lg}) {
                transform: translateY(20vh);
            }
            @media screen and (max-width: ${breakpoints.md}) {
                transform: translateY(10vh);
            }
        }
    }
   
    flex: 0 0  calc(26vw - 50px);
    height: calc(26vw - 50px);
    margin: 1vw;
    position: relative;
    background-size: cover;
    background-color: black;
    border-radius: 35px;
    overflow: hidden;
`;
export const PrestationTileImageStyled = styled.div`
    @media screen and (max-width: ${breakpoints.lg}) {
        height: calc(80vw);
        width: calc(80vw);
    }
    height: calc(26vw - 50px);
    width: calc(26vw - 50px);
    img {
        object-fit: cover;
        height: 100%;
        width: 100%;
    }
`
