import { motion } from 'framer-motion';
import { FC } from 'react';
import { Link } from 'react-scroll';
import logo from "../../../assets/image/logo.png";
import lotus from "../../../assets/image/lotus.png";
import { ImageLogo, LogoText, LogoTextContainer, StyledLogo } from './styled';

const MobileLogo: FC = () => {
    return (
        <StyledLogo>
            <ImageLogo>
                <LogoTextContainer>
                    <LogoText>
                        <motion.div animate={{ y: [-100, 0], opacity: [0, 1] }} >Energ</motion.div>
                        <motion.div animate={{ y: [-100, 0], opacity: [0, 1], transition: { delay: .200 } }} >&</motion.div>
                        <motion.div animate={{ y: [-100, 0], opacity: [0, 1], transition: { delay: .500 } }} >Sens</motion.div>
                    </LogoText>
                </LogoTextContainer>
                <img
                    className="container__logo__text__image"
                    src={logo}
                    alt="Logo Energ et Sens"
                />
            </ImageLogo>
            <Link
                activeClass="active"
                to="section-1"
                spy={true}
                smooth={true}
                offset={-10}
                duration={400}
            >
                <div className="container__scroll-action">
                    <img src={lotus} alt="" />
                </div>
            </Link>

        </StyledLogo>
    );
}
export default MobileLogo;